import { always } from 'ramda'
import { createReducer } from '../../utilities/miscellaneous'

export const INITIAL_STATE = {
  initialized: false,
  lastPageViewed: null,
  lastVideoViewed: null,
}

export const INITIALIZED = 'np6/INITIALIZED'
export const PAGE_VIEW_REQUEST = 'np6/PAGE_VIEW_REQUEST'
export const PAGE_VIEW_SENT = 'np6/PAGE_VIEW_SENT'
export const VIDEO_VIEW_REQUEST = 'np6/VIDEO_VIEW_REQUEST'
export const VIDEO_VIEW_SENT = 'np6/VIDEO_VIEW_SENT'

// initialized :: () -> Action.INITIALIZED
export const initialized = always({ type: INITIALIZED })

// pageViewRequest :: () -> Action.PAGE_VIEW_REQUEST
export const pageViewRequest = always({ type:PAGE_VIEW_REQUEST })

// pageViewSent :: String -> Action.PAGE_VIEW_SENT
export const pageViewSent = page => ({
  type: PAGE_VIEW_SENT,
  page,
})

// videoViewRequest :: () -> Action.VIDEO_VIEW_REQUEST
export const videoViewRequest = videoId => ({
  type:VIDEO_VIEW_REQUEST,
  videoId,
})

// videoViewSent :: String -> Action.VIDEO_VIEW_SENT
export const videoViewSent = videoId => ({
  type: VIDEO_VIEW_SENT,
  videoId,
})

// np6 :: (State, Action *) -> State
export default createReducer(INITIAL_STATE,  {
  [INITIALIZED]: state => ({
    ...state,
    initialized: true,
  }),

  [PAGE_VIEW_SENT]: (state, { page }) => ({
    ...state,
    lastPageViewed: page,
  }),

  [VIDEO_VIEW_SENT]: (state, { videoId }) => ({
    ...state,
    lastVideoViewed: videoId,
  }),
})
