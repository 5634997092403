import React, { createContext, useState } from 'react'
import styles from './Scrollable.module.css'

export const ScrollContext = createContext(null)

const Scrollable = ({ children }) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <ScrollContext.Provider value={ {
      enableScroll: () => setDisabled(false),
      disableScroll: () => setDisabled(true),
    } }>
      <div className={ disabled ? styles.disabled : undefined }>
        { children }
      </div>
    </ScrollContext.Provider>
  )
}

export default Scrollable