import React from 'react'
import styles from './Error.module.css'
import PropTypes from 'prop-types'

// Error :: Props -> React.Component
const Error = ({ id, children }) =>
  <p id={ id } className={ styles.message }>{ children }</p>

Error.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Error
