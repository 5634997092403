export const worldRegion = 'world'

export const usaRegion = 'usa'

export const validatedCommentStatus = 2

// 8 characters, at least 1 number and one letter
export const passwordPattern = '(?=^.{8,}$)(?=.*[a-zA-Z])(?=.*[0-9]).*$'

// i24NEWS logo url on the CDN
export const i24LogoUrl = 'https://cdn.i24news.tv/upload/image/1352b9caa849f9325c12157cf9dfc47bc0e3c73e.png'
