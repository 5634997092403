import {
  INITIALIZED,
  PAGE_VIEW_REQUEST,
  VIDEO_VIEW_REQUEST,
  initialized,
  pageViewRequest,
  pageViewSent,
  videoViewRequest,
  videoViewSent,
} from '../store/modules/np6'
import {
  T,
  always,
  complement,
  compose,
  cond,
  defaultTo,
  ifElse,
  isNil,
  path,
  pathOr,
  pipe,
  prop,
} from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import {
  delay,
  filter,
  map,
  mergeMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import { AUTHORIZE_NON_IAB_VENDORS } from '../store/modules/consentManagement'
import { TITLE_CHANGED } from '../store/modules/metas'
import { brightcoveVideoPlayed$ } from './index'
import { enLocale } from '../utilities/locales'
import { logObservableError } from '../utilities/logs'

const MARKER_BASE_CONFIG = {
  ezMarkerType: 'ncbh02v',
  npC: '02v',
  npA: 'ncbh',
  npHash: '2f09e887c5721ef34c4a371e86d13ca0',
  ezProvider: 'ncbh02v',
  ezHash: true,
}

// loadTrackingPixelEpic :: Epic -> Observable Action INITIALIZE
export const loadTrackingPixelEpic = (action$, state$, { document, logger, window }) =>
  action$.pipe(
    ofType(AUTHORIZE_NON_IAB_VENDORS),
    withLatestFrom(state$),
    filter(([ _, state ]) => state.consentManagement.nonIABVendorsAuthorized),
    filter(() => process.env.REACT_APP_NP6_MARKER_ID),
    take(1),
    mergeMap(() => new Promise(resolve => {
      window[process.env.REACT_APP_NP6_MARKER_ID] = {
        ...MARKER_BASE_CONFIG,
        // Necessary to instruct NP6 script not to send a page view on load.
        // Page views are managed by the epics below.
        manual: true,
      }

      const script = document.createElement('script')
      script.async = true
      script.src = `//pixel.np6.net/marker/ezMarkerName/${process.env.REACT_APP_NP6_MARKER_ID}?tjs=${new Date().getTime()}`
      script.onload = () => resolve()

      document.querySelector('head').appendChild(script)
    })),
    map(initialized),
    logObservableError('np6 :: loadNp6TrackingPixelEpic', logger),
  )

// requestPageViewOnInitializedEpic :: Epic -> Observable Action PAGE_VIEW_REQUEST
export const requestPageViewOnInitializedEpic =  (action$, state$, { logger }) =>
  action$.pipe(
    ofType(INITIALIZED),
    // We need to wait for the page to be fully loaded as NP6
    // tracker needs some information that are filled once the components are
    // fully mounted (ex. article slug, category name, etc...)
    delay(250),
    map(pageViewRequest),
    logObservableError('np6 :: requestPageViewOnInitializedEpic', logger),
  )

// requestPageViewOnTitleChangeEpic :: Epic -> Observable Action PAGE_VIEW_REQUEST
export const requestPageViewOnTitleChangeEpic =  (action$, state$, { logger }) =>
  action$.pipe(
    ofType(TITLE_CHANGED),
    // We need to wait for the page to be fully loaded as NP6
    // tracker needs some information that are filled once the components are
    // fully mounted (ex. article slug, category name, etc...)
    delay(250),
    map(pageViewRequest),
    logObservableError('np6 :: requestPageViewOnTitleChangeEpic', logger),
  )

// viewPageEpic :: Epic -> Observable Action PAGE_VIEW_SENT
export const viewPageEpic = (action$, state$, { getNp6Marker, location, logger }) =>
  action$.pipe(
    ofType(PAGE_VIEW_REQUEST),
    withLatestFrom(state$),
    filter(([ _, state ]) => state.consentManagement.nonIABVendorsAuthorized),
    filter(([ _, state ]) => state.np6.initialized),
    filter(([ _, state ]) => location.pathname !== state.np6.lastPageViewed),
    filter(() => !isNil(getNp6Marker())),
    tap(([ _, state ]) => getNp6Marker()
      .sendMarkerHit({
        ...MARKER_BASE_CONFIG,
        'ezC': [
          `lang:${resolveLocale(state)}`,
          `category:${resolvePageType(state)}`,
          `thematic:${resolveMainCategory(state)}`,
          `sub_thematic:${resolveSubCategory(state)}`,
          `id_article:${resolveArticleSlug(state)}`,
          `id_video:`,
        ],
        'ezUID': resolveUserIdentifier(state),
      }),
    ),
    map(() => pageViewSent(location.pathname)),
    logObservableError('np6 :: viewPageEpic', logger),
  )

// videoPlayedEpic :: Epic -> Observable Action VIDEO_VIEW_REQUEST
const videoPlayedEpic = (action$, state$, { logger }) =>
  brightcoveVideoPlayed$.pipe(
    filter(({ autoPlay }) => !autoPlay),
    map(({ videoId }) => videoViewRequest(videoId)),
    logObservableError('np6 :: videoPlayedEpic', logger),
  )

// viewVideoEpic :: Epic -> Observable Action VIDEO_VIEW_SENT
export const viewVideoEpic = (action$, state$, { getNp6Marker, logger }) =>
  action$.pipe(
    ofType(VIDEO_VIEW_REQUEST),
    withLatestFrom(state$),
    filter(([ _, state ]) => state.consentManagement.nonIABVendorsAuthorized),
    filter(([ _, state ]) => state.np6.initialized),
    filter(([ { videoId }, state ]) => videoId !== state.np6.lastVideoViewed),
    filter(() => !isNil(getNp6Marker())),
    tap(([ action, state ]) => getNp6Marker()
      .sendMarkerHit({
        ...MARKER_BASE_CONFIG,
        'ezC': [
          `lang:${resolveLocale(state)}`,
          `category:${resolvePageType(state)}`,
          `thematic:${resolveMainCategory(state)}`,
          `sub_thematic:${resolveSubCategory(state)}`,
          `id_article:${resolveArticleSlug(state)}`,
          `id_video:${action.videoId}`,
        ],
        'ezUID': resolveUserIdentifier(state),
      }),
    ),
    map(([ { videoId } ]) => videoViewSent(videoId)),
    logObservableError('np6 :: viewVideoEpic', logger),
  )

// resolveLocale :: State -> String
export const resolveLocale = pathOr(enLocale, ['router', 'locale'])

// resolvePageType :: State -> String
export const resolvePageType = pathOr('', ['router', 'activeRoute', 'name'])

// getCurrentCategory :: State -> Category|Null
const getCurrentCategory = cond([
  [
    compose(complement(isNil), path(['category', 'category', 'id'])),
    path(['category', 'category']),
  ],
  [
    compose(complement(isNil), path(['article', 'content', 'category', 'id'])),
    path(['article', 'content', 'category']),
  ],
])

// resolveCategory :: State -> String
export const resolveMainCategory = pipe(
  getCurrentCategory,
  ifElse(
    compose(isNil, prop('parent')),
    prop('name'),
    path(['parent', 'name']),
  ),
  defaultTo(''),
)

// resolveSubCategory :: State -> String
export const resolveSubCategory = pipe(
  getCurrentCategory,
  ifElse(
    compose(isNil, prop('parent')),
    always(''),
    prop('name'),
  ),
)

// resolveArticleSlug :: State -> String
export const resolveArticleSlug = cond([
  [
    compose(complement(isNil), path(['article', 'content', 'slug'])),
    path(['article', 'content', 'slug']),
  ],
  [T, always('')],
])

// resolveUserIdentifier :: State -> String
export const resolveUserIdentifier = pathOr(null, ['session', 'user', 'email'])

export default combineEpics(
  loadTrackingPixelEpic,
  requestPageViewOnInitializedEpic,
  requestPageViewOnTitleChangeEpic,
  viewPageEpic,
  videoPlayedEpic,
  viewVideoEpic,
)
